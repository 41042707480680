import React from 'react';
import {StyleSheet, View, Text, SafeAreaView, ScrollView, TouchableOpacity, RefreshControl, Image, Platform, TextInput, Switch} from 'react-native';
import BottomNavigationBar from '../components/BottomNavigationBar';
import ConsumptionRowHorizontal from '../components/ConsumptionRowHorizontal';
import config from '../config';
import cartService from '../services/cart';
import orderService from '../services/orders';
import AlertPopUpModal from '../components/AlertPopUpModal';
import I18n from '../utils/i18n';
import CartIcon from './../assets/icons/cart.svg';
import { formatPriceWithCurrencyCode, isWideMode } from '../utils/common';
import { useIsFocused } from '@react-navigation/native';
import RefreshButton from '../components/RefreshButton';
import commonStyles from './../styles/common';
import AsyncStorage from '@react-native-async-storage/async-storage';
import userService from './../services/user';
import barsService from './../services/bars';
import paymentsService from '../services/payments';
import { openLink } from '../utils/browser';

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.tableNumber = this.props?.route?.params?.tableNumber || this.props?.tableNumber;

    this.default_modal_props = {
      icon: '',
      title: '',
      text: '',
      okCallback: null
    }

    this.loading_modal_props = {
      ...this.default_modal_props,
      icon: 'loading',
      title: I18n.t('loading')
    }

    this.state = {
      cartItems: [],
      localCartItems: [],
      loading: false,
      placingOrder: false,
      isModalVisible: false,
      modal: {
        icon: '',
        title: '',
        text: '',
      },
      role: config.roles.USER.key,
      beeperNo: '',
      availablePaymentOptions: [],
      paymentType: null,
      paymentMethod: null,
      hidePaymentType: false,
      printerIp: [],
      tableNumbers: [],
      enableTableService: false,
      forceInputTableNumber: false,
      enableBeeper: false,
      markAsCompletedArray: [],
      selectedTableNumber: '',
      currencyCode: ''
    }
  }

  setStateAsync = (state) => {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

  async componentDidMount(show_loading = true, redirect_if_empty = false, keep_payment_type=false) {
    await this.setState({
      loading: true,
      isModalVisible: show_loading && true,
      modal: this.loading_modal_props,
      selectedTableNumber: this.tableNumber
    });

    let profileRes = await userService.get_profile();
    if (profileRes.error) {
      this.props.navigation.reset({
        index: 0,
        routes: [{ name: 'Login', params: { redirectTo: 'Cart', tableNumber: this.tableNumber } }],
      });
      return;
    }


    let role = await AsyncStorage.getItem('role');
    await this.setState({ role: role });

    if (role == config.roles.CASHIER.key || role == config.roles.SERVER.key) {
      let bar_id = (JSON.parse(await AsyncStorage.getItem(config.barsKey)))[0];
      let bar = await barsService.get_bar(bar_id);

      // TODO : Get available payment types for cashier / server
      let availablePaymentOptions = await paymentsService.get_available_payment_options();
      this.setState({
        availablePaymentOptions: availablePaymentOptions?.availablePaymentOptions ? availablePaymentOptions.availablePaymentOptions : []
      });
     
      let table_numbers = [];
      if (bar?.bar?.table_numbers?.length > 0) {
        table_numbers = bar.bar.table_numbers;
      }

      let hideDefaultPaymentType = profileRes?.user?.hideDefaultPaymentType ? true : false;

      this.setState({
        tableNumbers: table_numbers,
        enableTableService: bar?.bar?.enable_table_service,
        forceInputTableNumber: bar?.bar?.force_input_table_number,
        enableBeeper: bar?.bar?.enable_beeper,
        currencyCode: bar?.bar?.currencyCode,
        hidePaymentType: hideDefaultPaymentType
      });

      if (!keep_payment_type) {
        let defaultPaymentType = profileRes?.user?.defaultPaymentType ? profileRes.user.defaultPaymentType : null;
        let defaultPaymentMethod = profileRes?.user?.defaultPaymentMethod ? profileRes.user.defaultPaymentMethod : null;
        if (!(defaultPaymentType == config.paymentType.CASH.key || defaultPaymentType == config.paymentType.CARD.key)) {
          defaultPaymentType = null;
        }
        this.setState({
          paymentType: defaultPaymentType,
          paymentMethod: defaultPaymentMethod
        });
      }

    }


    let cartRes = await cartService.get_cart_details();
    if (cartRes.error) {
      this.setState({
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(cartRes.error_code),
          okCallback: () => {
            this.setState({
              isModalVisible: false
            });
            // redirect to home
            this.props.navigation.navigate('Home');
          }
        }
      });
      return;
    }

    let cartItemsClone = JSON.parse(JSON.stringify(cartRes.cart?.items ? cartRes.cart.items : []));

    if (cartItemsClone.length > 0) {
      let bar_id = cartItemsClone?.[0]?.bar?._id ? cartItemsClone[0].bar._id : cartItemsClone[0].bar;
      let bar = await barsService.get_bar(bar_id);
      let currencyCode = bar?.bar?.currencyCode;
      if (currencyCode) {
        this.setState({ currencyCode: currencyCode });
      }

      if (role == config.roles.USER.key) {
        // TODO : get available payment types
        let availablePaymentOptionsPayload = {
          bar: bar_id
        };
        if (this.state.selectedTableNumber) {
          availablePaymentOptionsPayload['tableNumber'] = this.state.selectedTableNumber;
        }
        let availablePaymentOptions = await paymentsService.get_available_payment_options(availablePaymentOptionsPayload);
        this.setState({
          availablePaymentOptions: availablePaymentOptions?.availablePaymentOptions ? availablePaymentOptions.availablePaymentOptions : []
        });
      }

    }

    // get unique groups from cart
    let markAsCompletedArray = [];
    // mark as completed is an object with _id and name
    cartItemsClone.forEach((item) => {
      let group = item.consumption.group;
      if (group) {
        let groupIndex = markAsCompletedArray.findIndex((markAsCompletedItem) => markAsCompletedItem._id === group._id);
        if (groupIndex === -1) {
          markAsCompletedArray.push({
            ...group,
            value: group?.defaultMarkAsCompleted ? group.defaultMarkAsCompleted : false
          });
        }
      }
    });

    this.setState({
      cartItems: cartRes.cart?.items ? cartRes.cart.items : [],
      localCartItems: cartItemsClone,
      loading: false,
      isModalVisible: show_loading && false,
      markAsCompletedArray: markAsCompletedArray
    });

    if (this.getTotalCost() <= 0) {
      this.setState({
        paymentType: config.paymentType.CASH.key,
        paymentMethod: null
      });
    }

    if (redirect_if_empty && cartItemsClone.length === 0) {
      this.props.navigation.navigate('Home');
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.isFocused !== this.props.isFocused) {
      if (this.props.isFocused) {
        this.componentDidMount();
      }
    }

    if (prevProps.handleClearCart !== this.props.handleClearCart) {
      if (this.props.handleClearCart) {
        this.deleteCart();
      }
    }
  }

  async addConsumptionToCart(consumption) {
    let localCartItems = this.state.localCartItems;
    let consumptionIndex = localCartItems.findIndex((item) => item.consumption._id === consumption.consumption._id);

    if (consumptionIndex === -1) {
      localCartItems.push({
        bar: consumption.bar,
        group: consumption.group,
        category: consumption.category,
        consumption: consumption.consumption,
        quantity: 1
      });
    } else {
      localCartItems[consumptionIndex].quantity++;
    }

    this.setState({ localCartItems: localCartItems });

    if (this.getTotalCost() <= 0) {
      this.setState({
        paymentType: config.paymentType.CASH.key,
        paymentMethod: null
      });
    }
  }
  
  async removeConsumptionFromCart(consumption) {
    let localCartItems = this.state.localCartItems;
    let consumptionIndex = localCartItems.findIndex((item) => item.consumption._id === consumption.consumption._id);

    if (consumptionIndex !== -1) {
      if (localCartItems[consumptionIndex].quantity === 1 || localCartItems[consumptionIndex].quantity === 0) {
        localCartItems[consumptionIndex].quantity = 0;
      } else {
        localCartItems[consumptionIndex].quantity--;
      }
    }

    this.setState({ localCartItems: localCartItems });

    if (this.getTotalCost() <= 0) {
      this.setState({
        paymentType: config.paymentType.CASH.key,
        paymentMethod: null
      });
    }
  }
  
  async setConsumptionQuantityInCart(consumption, quantity) {
    let localCartItems = this.state.localCartItems;
    let consumptionIndex = localCartItems.findIndex((item) => item.consumption._id === consumption.consumption._id);

    if (consumptionIndex !== -1) {
      localCartItems[consumptionIndex].quantity = quantity;
    }

    this.setState({ localCartItems: localCartItems });

    if (this.getTotalCost() <= 0) {
      this.setState({
        paymentType: config.paymentType.CASH.key,
        paymentMethod: null
      });
    }
  }

  getCurrentQuantity(localCartItems, consumption) {
    let consumptionIndex = localCartItems.findIndex((item) => item.consumption._id === consumption);
    if (consumptionIndex === -1) {
      return 0;
    } else {
      return localCartItems[consumptionIndex]?.quantity;
    }
  }

  getTotalItems() {
    let totalItems = 0;
    this.state.localCartItems.forEach((item) => {
      if (item.quantity > 0) {
        if (item?.consumption?.type == config.consumptionInputType.INPUT.key) {
          totalItems++;
        } else {
          totalItems += item.quantity;
        }
      }
    });
    return totalItems;
  }

  getTotalCost() {
    let totalCost = 0;
    this.state.localCartItems.forEach((item) => {
      if (item.quantity > 0) {
        totalCost += item.quantity * item.consumption.price;
      }
    });
    return totalCost;
  }

  getCurrencyCode() {
    return this.state?.currencyCode;
  }

  // compare two array of objects - localCartItems and cartItems
  // if there is a difference, return true
  // else return false
  isCartChanged() {
    let localCartItems = this.state.localCartItems;
    let cartItems = this.state.cartItems;

    if (localCartItems.length !== cartItems.length) {
      return true;
    }

    for (let i = 0; i < localCartItems.length; i++) {

      let cartItemIdx = cartItems.findIndex((item) => item.consumption._id === localCartItems[i].consumption._id);

      if (cartItemIdx === -1) {
        return true;
      }

      if (localCartItems[i].quantity !== cartItems[cartItemIdx].quantity) {
        return true;
      }
    }

    return false;
  }

  async saveCart() {

    if (!this.isCartChanged()) {
      return;
    }

    this.setState({
      loading: true,
      isModalVisible: true,
      modal: this.loading_modal_props
    });

    let localCartItems = this.state.localCartItems;
    // remove items with quantity 0
    localCartItems = localCartItems.filter((item) => item.quantity > 0);
    let localCartItemsToSave = localCartItems.map((item) => {
      return {
        bar: item.bar?._id || item.bar,
        group: item.group?._id || item.group,
        category: item.category?._id || item.category,
        consumption: item.consumption?._id || item.consumption,
        quantity: item.quantity
      }
    });

    await cartService.put_local_cart(localCartItemsToSave);
    
    this.setState({
      loading: false,
      isModalVisible: true,
      modal: {
        ...this.default_modal_props,
        icon: 'success',
        title: I18n.t('success'),
        text: I18n.t('cart_saved'),
        okCallback: () => {
          this.setState({
            loading: false,
            isModalVisible: false
          });
          this.componentDidMount(true, true, true);
        }
      }
    });
  }

  // async conf(msg) {
  //   return new Promise((resolve, reject) => {
  //     this.setState({
  //       isModalVisible: true,
  //       modal: {
  //         icon: 'info',
  //         title: msg,
  //         okCallback: () => {
  //           resolve(true);
  //           this.setState({
  //             isModalVisible: false,
  //           });
  //         },
  //         okText: I18n.t('yes'),
  //         cancelCallback: () => {
  //           resolve(false);
  //           this.setState({
  //             isModalVisible: false
  //           })
  //         },
  //         cancelText: I18n.t('no')
  //       }
  //     });
  //   });
  // }

  async isFormValid() {
    if (this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) {
      if (this.state.enableTableService && this.state.forceInputTableNumber && !this.state.selectedTableNumber) {
        this.setState({
          isModalVisible: true,
          modal: {
            ...this.default_modal_props,
            icon: 'error',
            title: I18n.t('error'),
            text: I18n.t('please_enter_table_number'),
            okCallback: () => {
              this.setState({
                isModalVisible: false
              });
            }
          }
        });
        return false;
      }
    }
    return true;
  }

  async placeOrder(paymentType=null, paymentMethod=null) {

    if (!await this.isFormValid()) {
      return;
    }

    this.setState({
      loading: true,
      isModalVisible: true,
      modal: this.loading_modal_props
    });

    let payload = {};

    if(this?.state?.selectedTableNumber?.length) {
      payload['tableNumber'] = this.state.selectedTableNumber;
    }

    if(this?.state?.beeperNo?.length) {
      payload['beeperNo'] = this.state.beeperNo;
    }

    if (this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) {
      let markAsCompletedObj = {};
      this.state.markAsCompletedArray.forEach((item) => {
        markAsCompletedObj[String(item._id)] = item.value;
      });
      payload['markAsCompletedObj'] = markAsCompletedObj;
    } else if (this.state.role == config.roles.USER.key) {
      payload['markAsCompletedObj'] = {};
    }

    payload['paymentType'] = paymentType;
    payload['paymentMethod'] = paymentMethod;

    let localCartItems = this.state.localCartItems;
    localCartItems = localCartItems.filter((item) => item.quantity > 0);
    let localCartItemsToOrder = localCartItems.map((item) => {
      return {
        bar: item.bar?._id || item.bar,
        group: item.group?._id || item.group,
        category: item.category?._id || item.category,
        consumption: item.consumption?._id || item.consumption,
        quantity: item.quantity
      }
    });

    payload['items'] = localCartItemsToOrder;

    let orderRes = await orderService.place_order_from_local_cart(payload);
    if (orderRes.error) {
      this.setState({
        loading: false,
        isModalVisible: false
      });
      this.setState({
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'error',
          title: I18n.t('error'),
          text: I18n.t(orderRes.error_code),
          okCallback: () => {
            this.setState({
              loading: false,
              isModalVisible: false
            });
          }
        }
      });
      return;
    }

    cartService.delete_local_cart();

    await this.setStateAsync({
      loading: false,
      isModalVisible: false
    });

    if (orderRes.payrexxLink) {
      let browser_res = await openLink(orderRes.payrexxLink, 'orderGroup', 'paymentStatus', orderRes?.orderGroup?._id);
      if (browser_res.error) {
        this.props.navigation.navigate('Payment', {
          payrexxLink: orderRes.payrexxLink,
          orderGroupId: orderRes.orderGroup._id,
          payrexxId: orderRes.orderGroup.payrexxId,
          payrexxReferenceId: orderRes.orderGroup.payrexxReferenceId,
          redirectTo: (this.state.role == config.roles.USER.key) ? 'Orders' : (isWideMode() ? 'OrderGroups' : 'OrderGroupDetails')
        });
      } else {
        this.props.navigation.reset({
          index: 0,
          routes: [{
            name: (this.state.role == config.roles.USER.key) ? 'Orders' : (isWideMode() ? 'OrderGroups' : 'OrderGroupDetails'),
            params: { orderId: null, orderGroupId: orderRes.orderGroup._id, payrexxId: orderRes.orderGroup.payrexxId, payrexxReferenceId: orderRes.orderGroup.payrexxReferenceId } 
          }],
        });
      }

    } else if (orderRes.twintDirectPaymentDetails) {
      this.props.navigation.navigate('TwintDirectPayment', {
        role: this.state.role,
        merchantTranId: orderRes.twintDirectPaymentDetails.merchantTranId,
        token: orderRes.twintDirectPaymentDetails.token,
        orderGroupId: orderRes.orderGroup._id,
        redirectTo: (this.state.role == config.roles.USER.key) ? 'Orders' : (isWideMode() ? 'OrderGroups' : 'OrderGroupDetails'),
      });
    } else if (orderRes.cardTransactionDetails) {
      this.props.navigation.navigate('CardPayment', {
        role: this.state.role,
        ruid: orderRes.cardTransactionDetails.ruid,
        orderGroupId: orderRes.orderGroup._id,
        redirectTo: (this.state.role == config.roles.USER.key) ? 'Orders' : (isWideMode() ? 'OrderGroups' : 'OrderGroupDetails'),
      });
    } else {

      this.setState({
        loading: false,
        isModalVisible: true,
        modal: {
          ...this.default_modal_props,
          icon: 'success',
          title: formatPriceWithCurrencyCode(this.getTotalCost(), this.getCurrencyCode()),
          text: I18n.t('order_placed'),
          okText: (this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? I18n.t('ok') : I18n.t('view_orders'),
          okCallback: () => {
            this.setState({
              loading: false,
              isModalVisible: false
            });
            this.componentDidMount();

            if (this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) {
              // go home with reset index
              this.props.navigation.reset({
                index: 0,
                routes: [{ name: 'Home' }],
              });
            } else {
              this.props.navigation.navigate('Orders');
            }
          }
        }
      });

    }

    // reset cart
    this.setState({
      localCartItems: [],
      cartItems: []
    });

  }

  async deleteCartConfirmed() {

    this.setState({
      loading: true,
      isModalVisible: true,
      modal: this.loading_modal_props
    });

    await cartService.delete_local_cart();
    
    this.setState({
      loading: false,
      isModalVisible: true,
      modal: {
        ...this.default_modal_props,
        icon: 'success',
        title: I18n.t('success'),
        text: I18n.t('cart_is_now_empty'),
        okCallback: () => {
          this.setState({
            loading: false,
            isModalVisible: false
          });
          this.props.navigation.navigate('Home');
        }
      }
    });
    this.props.navigation.setParams({ handleClearCart: false });
  }

  deleteCart() {

    let text = '';
    let okText = '';
    if (this.state.role == config.roles.CASHIER.key) {
      text = I18n.t('do_you_want_to_cancel_order');
      okText = I18n.t('cancel_order');
    } else {
      text = I18n.t('do_you_want_to_clear_cart');
      okText = I18n.t('clear_cart');
    }

    this.setState({
      loading: false,
      isModalVisible: true,
      modal: {
        ...this.default_modal_props,
        icon: 'warning',
        title: I18n.t('warning'),
        text: text,
        okText: okText,
        okCallback: () => {
          this.deleteCartConfirmed();
        },
        cancelCallback: () => {
          this.setState({
            loading: false,
            isModalVisible: false
          });
          this.props.navigation.setParams({ handleClearCart: false });
        }
      }
    });
  }

  tableNumberSelectionView() {

    let styles = {
      wideModeButton: {
        minWidth: 100, margin: 5, borderColor: config.primaryColor, borderWidth: 2, borderRadius: 10, justifyContent: 'center', alignItems: 'center', padding: 10, marginLeft: 10, marginRight: 10
      },
      wideModeSelectedButton: {
        minWidth: 100, margin: 5, borderColor: config.primaryColor, borderWidth: 2, borderRadius: 10, justifyContent: 'center', alignItems: 'center', padding: 10, marginLeft: 10, marginRight: 10, backgroundColor: config.primaryColor
      },
      normalModeButton: {
        minWidth: 50, margin: 5, borderColor: config.primaryColor, borderWidth: 2, borderRadius: 10, justifyContent: 'center', alignItems: 'center', padding: 5, marginLeft: 10, marginRight: 10, paddingTop: 0, paddingBottom: 0
      },
      normalModeSelectedButton: {
        minWidth: 50, margin: 5, borderColor: config.primaryColor, borderWidth: 2, borderRadius: 10, justifyContent: 'center', alignItems: 'center', padding: 5, marginLeft: 10, marginRight: 10, backgroundColor: config.primaryColor, paddingTop: 0, paddingBottom: 0
      },
      wideModeText: {
        color: config.primaryColor, fontSize: 50
      },
      wideModeSelectedText: {
        color: 'white', fontSize: 50
      },
      normalModeText: {
        color: config.primaryColor, fontSize: 30
      },
      normalModeSelectedText: {
        color: 'white', fontSize: 30
      }
    }

    let buttonStyle = isWideMode() ? styles.wideModeButton : styles.normalModeButton;
    let selectedButtonStyle = isWideMode() ? styles.wideModeSelectedButton : styles.normalModeSelectedButton;
    let textStyle = isWideMode() ? styles.wideModeText : styles.normalModeText;
    let selectedTextStyle = isWideMode() ? styles.wideModeSelectedText : styles.normalModeSelectedText;

    return (
      <ScrollView scrollEnabled={true} horizontal={true}>
        {this.state.tableNumbers.map((tableNumber, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={this.state.selectedTableNumber == tableNumber ? selectedButtonStyle : buttonStyle}
              onPress={() => {
                if (this.state.selectedTableNumber == tableNumber) {
                  tableNumber = '';
                }
                this.setState({
                  selectedTableNumber: tableNumber
                });
              }}
            >
              <Text style={this.state.selectedTableNumber == tableNumber ? selectedTextStyle: textStyle}>{tableNumber}</Text>
            </TouchableOpacity>
          )
        })}
      </ScrollView>
    )
  }

  isPaymentOptionAvailable(paymentType, paymentMethod) {
    return this.state.availablePaymentOptions.find((item) => (item.paymentType == paymentType && item.paymentMethod == paymentMethod));
  }


  // new Code
  bottomButtonsView() {
    return (
        this.state.localCartItems.length > 0 ? (
          <View>

            {(this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? 
              (this.state.enableTableService ? this.tableNumberSelectionView() : null) :
              null
            }

            <View style={{justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginHorizontal: 10}}>
              <Text style={{color: 'black', fontSize: isWideMode() ? 30 : 20, fontWeight: '500'}}>{I18n.t('total_items')} : {this.getTotalItems()}</Text>
              <Text style={{color: 'black', fontSize: isWideMode() ? 50 : 30, fontWeight: '500'}}>{formatPriceWithCurrencyCode(this.getTotalCost(), this.getCurrencyCode())}</Text>
            </View>

            {(this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? (
              <>
                <View>
                  {this.state.markAsCompletedArray.map((item, index) => {
                    return (
                      <View style={{flexDirection: 'row', marginHorizontal: 10, marginVertical: 10}} key={item._id}>
                        <Switch
                          style={{marginHorizontal: 10}}
                          onValueChange={(value) => {
                            let markAsCompletedArray = this.state.markAsCompletedArray;
                            markAsCompletedArray[index].value = value;
                            this.setState({markAsCompletedArray: markAsCompletedArray});
                          }}
                          value={this.state.markAsCompletedArray[index].value}
                        />
                        <Text
                          style={{fontSize: (Platform.OS == 'android' ? 17 : 20), fontWeight: '700', color: 'black', marginHorizontal: 10}}
                          onClick={() => {
                            let markAsCompletedArray = this.state.markAsCompletedArray;
                            markAsCompletedArray[index].value = !markAsCompletedArray[index].value;
                            this.setState({markAsCompletedArray: markAsCompletedArray});
                          }}
                        >{item.name + ' - ' + I18n.t('mark_as_completed')}</Text>
                      </View>
                    )
                  })}
                </View>

                {(this.state.enableBeeper || this.state.enableTableService) ? (
                  <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                    {(this.state.enableTableService) ? (
                      <View style={{flex: 1, margin: 10, marginTop: isWideMode() ? 10 : 0, marginBottom: isWideMode() ? 10 : 10}}>
                        <TextInput
                          style={{
                            backgroundColor: 'white', color: 'black', padding: 10, margin: 10, marginBottom: isWideMode() ? 10 : 0, borderRadius: 10, alignItems: 'center', borderColor: config.primaryColor, borderWidth: 1, textAlign: 'center', fontSize: isWideMode() ? 30 : 15,
                            height: (Platform.OS == 'android') ? 50 : 'auto'
                          }}
                          placeholder={I18n.t('table_number')}
                          value={this.state.selectedTableNumber}
                          placeholderTextColor={"gray"}
                          onChangeText={(text) => {
                            this.setState({selectedTableNumber: text})
                          }}
                        />
                      </View>
                    ) : null}
                    {(this.state.enableBeeper) ? (
                      <View style={{flex: 1, margin: 10, marginTop: isWideMode() ? 10 : 0, marginBottom: isWideMode() ? 10 : 10}}>
                        <TextInput
                          style={{
                            backgroundColor: 'white', color: 'black', padding: 10, margin: 10, marginBottom: isWideMode() ? 10 : 0, borderRadius: 10, alignItems: 'center', borderColor: config.primaryColor, borderWidth: 1, textAlign: 'center', fontSize: isWideMode() ? 30 : 15,
                            height: (Platform.OS == 'android') ? 50 : 'auto'
                          }}
                          placeholder={I18n.t('beeper_number')}
                          value={this.state.beeperNo}
                          placeholderTextColor={"gray"}
                          keyboardType={"numeric"}
                          onChangeText={(text) => {
                            this.setState({beeperNo: text})
                          }}
                        />
                      </View>
                    ) : null}
                  </View>
                ) : null}
              </>
            ) : null}

            {this.isCartChanged() ? (
              <TouchableOpacity
                style={{backgroundColor: config.primaryColor, padding: 10, borderRadius: 10, paddingTop: isWideMode() ? 30 : 10, paddingBottom: isWideMode() ? 30 : 10, margin: 10}}
                onPress={() => {
                  this.saveCart();
                }}
              >
                <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                  {I18n.t('save_cart')}
                </Text>
              </TouchableOpacity>
            ) : (
              null
            )}

            {(this.state.role == config.roles.USER.key && !this.isCartChanged()) ? (
                <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                  {(this.state.availablePaymentOptions.find((item) => (item.paymentType == config.paymentType.ONLINE.key && item.paymentMethod == config.paymentMethod.PAYREXX.key))) ? (
                    <View style={{flex: 1}}>
                      <TouchableOpacity
                        style={{backgroundColor: this.state.placingOrder ? 'gray' : 'green', padding: 10, borderRadius: 10, margin: 5, justifyContent: 'center', alignItems: 'center' }}
                        onPress={async () => {
                          this.setState({
                            placingOrder: true
                          });
                          await this.placeOrder(config.paymentType.ONLINE.key, config.paymentMethod.PAYREXX.key);  // paymentType is null for user
                          this.setState({
                            placingOrder: false
                          });
                        }}
                        disabled={this.state.placingOrder}
                      >
                        <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                          {this.state.placingOrder ? I18n.t('loading') : (this.isPaymentOptionAvailable(config.paymentType.ONLINE.key, config.paymentMethod.TWINT.key) ? I18n.t('pay_by_card') : I18n.t('pay'))}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ) : null}
                  {(this.state.availablePaymentOptions.find((item) => (item.paymentType == config.paymentType.ONLINE.key && item.paymentMethod == config.paymentMethod.TWINT.key))) ? (
                    <View style={{flex: 1}}>
                      <TouchableOpacity
                        style={{backgroundColor: this.state.placingOrder ? 'gray' : 'green', padding: 10, borderRadius: 10, margin: 5, justifyContent: 'center', alignItems: 'center' }}
                        onPress={async () => {
                          this.setState({
                            placingOrder: true
                          });
                          await this.placeOrder(config.paymentType.ONLINE.key, config.paymentMethod.TWINT.key);  // paymentType is null for user
                          this.setState({
                            placingOrder: false
                          });
                        }}
                        disabled={this.state.placingOrder}
                      >
                        <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                          {this.state.placingOrder ? I18n.t('loading') : I18n.t('pay_by_twint')}
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ) : null}
                  {(this.state.availablePaymentOptions.find((item) => (!item.paymentType && !item.paymentMethod))) ? (
                    <View style={{flex: 1}}>
                      <TouchableOpacity
                        style={{backgroundColor: this.state.placingOrder ? 'gray' : 'green', padding: 10, borderRadius: 10, margin: 5, justifyContent: 'center', alignItems: 'center' }}
                        onPress={async () => {
                          this.setState({
                            placingOrder: true
                          });
                          await this.placeOrder(null, null);
                          this.setState({
                            placingOrder: false
                          });
                        }}
                        disabled={this.state.placingOrder}
                      >
                        <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                          {((this.isPaymentOptionAvailable(config.paymentType.ONLINE.key, config.paymentMethod.TWINT.key)) || (this.isPaymentOptionAvailable(config.paymentType.ONLINE.key, config.paymentMethod.PAYREXX.key))) ?
                            I18n.t('pay_later') : I18n.t('place_order')
                          }
                        </Text>
                      </TouchableOpacity>
                    </View>
                  ) : null}
                </View>
            ) : null}

            {((this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) && !this.isCartChanged() && !this.state.hidePaymentType) ? (
              <>
              <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                {(this.state.availablePaymentOptions.find((item) => (item.paymentType == config.paymentType.CASH.key && !item.paymentMethod))) ? (
                  <View style={{flex: 1}}>
                    <TouchableOpacity
                      style={{backgroundColor: this.state.placingOrder ? 'gray' : 'green', padding: 10, borderRadius: 10, margin: 5, justifyContent: 'center', alignItems: 'center' }}
                      onPress={async () => {
                        this.setState({
                          placingOrder: true
                        });
                        await this.placeOrder(config.paymentType.CASH.key, null);
                        this.setState({
                          placingOrder: false
                        });
                      }}
                    >
                      <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                        {I18n.t('cash')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : null}
                {(this.state.availablePaymentOptions.find((item) => (item.paymentType == config.paymentType.CARD.key && !item.paymentMethod))) ? (
                  <View style={{flex: 1}}>
                    <TouchableOpacity
                      style={{backgroundColor: (this.state.placingOrder || (this.getTotalCost() <= 0)) ? 'gray' : 'green', padding: 10, borderRadius: 10, margin: 5, justifyContent: 'center', alignItems: 'center' }}
                      onPress={async () => {
                        this.setState({
                          placingOrder: true
                        });
                        await this.placeOrder(config.paymentType.CARD.key, null);
                        this.setState({
                          placingOrder: false
                        });
                      }}
                      disabled={(this.getTotalCost() <= 0) || this.state.placingOrder}
                    >
                      <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                        {I18n.t('card')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : null}
              </View>
              <View style={{flexDirection: 'row', justifyContent: 'space-around'}}>
                {(this.state.availablePaymentOptions.find((item) => (item.paymentType == config.paymentType.TWINT.key && !item.paymentMethod))) ? (
                  <View style={{flex: 1}}>
                    <TouchableOpacity
                      style={{backgroundColor: (this.state.placingOrder || (this.getTotalCost() <= 0)) ? 'gray' : 'green', padding: 10, borderRadius: 10, margin: 5, justifyContent: 'center', alignItems: 'center' }}
                      onPress={async () => {
                        this.setState({
                          placingOrder: true
                        });
                        await this.placeOrder(config.paymentType.TWINT.key, null);
                        this.setState({
                          placingOrder: false
                        });
                      }}
                      disabled={(this.getTotalCost() <= 0) || this.state.placingOrder}
                    >
                      <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                        {I18n.t('twint')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : null}
                {(this.state.availablePaymentOptions.find((item) => (item.paymentType == config.paymentType.PAYREXX.key && !item.paymentMethod))) ? (
                  <View style={{flex: 1}}>
                    <TouchableOpacity
                      style={{backgroundColor: this.state.placingOrder ? 'gray' : 'green', padding: 10, borderRadius: 10, margin: 5, justifyContent: 'center', alignItems: 'center' }}
                      onPress={async () => {
                        this.setState({
                          placingOrder: true
                        });
                        await this.placeOrder(config.paymentType.PAYREXX.key, null);
                        this.setState({
                          placingOrder: false
                        });
                      }}
                      disabled={(this.getTotalCost() <= 0) || this.state.placingOrder}
                    >
                      <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                        {/* {I18n.t('twint') + ' (' + I18n.t('payrexx') + ')'} */}
                        {I18n.t('twint')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : null}
                {(this.state.availablePaymentOptions.find((item) => (!item.paymentType && !item.paymentMethod))) ? (
                  <View style={{flex: 1}}>
                    <TouchableOpacity
                      style={{backgroundColor: this.state.placingOrder ? 'gray' : 'green', padding: 10, borderRadius: 10, margin: 5, justifyContent: 'center', alignItems: 'center' }}
                      onPress={async () => {
                        this.setState({
                          placingOrder: true
                        });
                        await this.placeOrder(null, null);
                        this.setState({
                          placingOrder: false
                        });
                      }}
                      disabled={this.state.placingOrder}
                    >
                      <Text style={{color: 'white', fontSize: isWideMode() ? 30 : 20, fontWeight: '500', textAlign: 'center'}}>
                        {I18n.t('pay_later')}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ) : null}
              </View>
              </>
            ) : null}

          </View>
        ) : null
    )
  }



  render() {

    if (!this.state.localCartItems.length) {
      return (
        <>
        <SafeAreaView style={styles.container}>
          <View style={commonStyles.centeredView}>
          <ScrollView
            style={styles.scrollView}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'center' }}
            refreshControl={
              <RefreshControl
                refreshing={this.state.loading}
                onRefresh={() => {
                  this.componentDidMount(false);
                }}
              />
            }
          >

            <View style={{ alignItems: 'center' }}>
              {(Platform.OS == "web") ? (
                <Image source={require('./../assets/icons/cart.svg')} style={{ width: 100, height: 100 }} />
              ) : (
                <CartIcon width={100} height={100} />
              )}
              <Text style={{fontSize: 30, fontWeight: '600', marginTop: 30, color: config.primaryColor}}>
                {I18n.t('cart_is_empty')}
              </Text>
              {(Platform.OS == 'web') ? (
                <RefreshButton
                  onPress={() => {
                    this.componentDidMount(false);
                  }}
                  refreshing={this.state.loading}
                />
              ) : null}
            </View>
          </ScrollView>
          <View>
            <BottomNavigationBar {...this.props} activeTab="Cart" />
          </View>
          </View>
        </SafeAreaView>
        <AlertPopUpModal
          isVisible={this.state.isModalVisible}
          icon={this.state.modal.icon}
          title={this.state.modal.title}
          text={this.state.modal.text}
          okText={this.state.modal.okText}
          okCallback={this.state.modal.okCallback}
          cancelText={this.state.modal.cancelText}
          cancelCallback={this.state.modal.cancelCallback}
        >
          {this.state?.modal?.children}
        </AlertPopUpModal>
        </>
      );
    }

    return (
      <>
      <SafeAreaView style={styles.container}>
        <View style={{width: '100%', height: '100%', flex: 1, flexDirection: 'row'}}>
        <View style={isWideMode() ? commonStyles.leftSideView : commonStyles.centeredView}>
        <ScrollView style={styles.scrollView}
          refreshControl={
            <RefreshControl
              refreshing={this.state.loading}
              onRefresh={() => {
                this.componentDidMount(false);
              }}
            />
          }
        >
          {(Platform.OS == 'web') ? (
            <RefreshButton
              onPress={() => {
                this.componentDidMount(false);
              }}
              refreshing={this.state.loading}
            />
          ) : null}

          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <View style={{ width: '100%' }}>
              
              {this.state.localCartItems.map((item, index) => {
                return (
                  <ConsumptionRowHorizontal
                    key={index}
                    disabled={Platform.OS == "web" ? false : true}
                    image_link={item?.consumption?.assets?.[0] ? generateAssetLink(item.consumption.assets[0]) : null}
                    title={item?.consumption?.name}
                    secondary_title={formatPriceWithCurrencyCode(item?.consumption?.price, this.getCurrencyCode())}
                    type={item?.consumption?.type}
                    round={item?.consumption?.round}
                    description={(this.state.role == config.roles.CASHIER.key || this.state.role == config.roles.SERVER.key) ? null : item?.bar?.name}
                    current_quantity={this.getCurrentQuantity(this.state.localCartItems, item?.consumption?._id)}
                    addCallback={async () => {
                        await this.addConsumptionToCart(item);
                    }}
                    removeCallback={async () => {
                        await this.removeConsumptionFromCart(item);
                    }}
                    changeCallback={async (quantity) => {
                      await this.setConsumptionQuantityInCart(item, quantity);
                    }}
                  />
                )
              })}
              
            </View>
          </View>
        </ScrollView>
        {!isWideMode() ? (
          <View>
            {this.bottomButtonsView()}
          </View>
        ) : null}
        <View>
          <BottomNavigationBar {...this.props} activeTab="Cart" />
        </View>
        </View>
        {isWideMode() ? (
          <View style={{...commonStyles.rightSideView, justifyContent: 'flex-end'}}>
            <ScrollView
              contentContainerStyle={{ flexGrow: 1, justifyContent: 'flex-end' }}
            >
              {this.bottomButtonsView()}
            </ScrollView>
          </View>
        ) : null}
        </View>
      </SafeAreaView>
      <AlertPopUpModal
        isVisible={this.state.isModalVisible}
        icon={this.state.modal.icon}
        title={this.state.modal.title}
        text={this.state.modal.text}
        okText={this.state.modal.okText}
        okCallback={this.state.modal.okCallback}
        cancelText={this.state.modal.cancelText}
        cancelCallback={this.state.modal.cancelCallback}
      >
        {this.state?.modal?.children}
      </AlertPopUpModal>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  scrollView: {
    backgroundColor: config.backgroundColor,
    marginHorizontal: 0,
  },
  text: {
    fontSize: 42,
  },
});

// Wrap and export
export default function(props) {
  const isFocused = useIsFocused();
  const handleClearCart = props?.route?.params?.handleClearCart;
  
  return <Cart {...props} isFocused={isFocused} handleClearCart={handleClearCart} />;
}

// export default Cart;
