import { Dimensions, Platform } from "react-native";
import config from "../config";
import I18n from "../utils/i18n";

function formatPrice(amount = null) {
    if (amount === null || amount === undefined) {
        return '';
    }
    try {
        return Number(amount).toFixed(2);
    } catch (e) {
        return '';
    }
}

function formatPriceWithCurrencyCode(amount = null, currencyCode = null, reversedCurrency = false) {
    if (amount === null || amount === undefined) {
        return '';
    }
    if (!currencyCode) {
        currencyCode = config.currencyCode;
    }
    try {
        if(reversedCurrency) {
            return `${Number(amount).toFixed(2)} ${currencyCode}`;
        }
        return `${currencyCode} ${Number(amount).toFixed(2)}`;
    } catch (e) {
        return '';
    }
}

function generateAssetLink(assetId) {
    return `${config.baseUrl}/assets/${assetId}`;
}

function isWideMode() {
    if (Platform.OS == "web") {
        return Dimensions.get('window').width >= 768;
    } else {
        return Dimensions.get('screen').width >= 768;
    }
}

function getHeight() {
    if (Platform.OS == "web") {
        return Dimensions.get('window').height;
    } else {
        return Dimensions.get('screen').height;
    }
}

function isMiniHeight() {
    if (Platform.OS == "web") {
        return Dimensions.get('window').height < 800;
    } else {
        return Dimensions.get('screen').height < 800;
    }
}

function isTooMiniHeight() {
    if (Platform.OS == "web") {
        return Dimensions.get('window').height < 600;
    } else {
        return Dimensions.get('screen').height < 600;
    }
}

function convertMessageCodeToMessage(messageCode){

    // If there is no | in messageCode, return simple translated message code
    if(messageCode.indexOf('|') == -1){
        return I18n.t(messageCode);
    }

    let codes = messageCode.match(/(?:\|)[^\|\|]*?(?:\|)/g) || [];
    let message = messageCode;
    for(let i=0; i<codes.length; i++){
        message = message.replace(codes[i], I18n.t(codes[i].replace(/\|/g, '')));
    }
    return message;
}

export {
    formatPrice,
    formatPriceWithCurrencyCode,
    generateAssetLink,
    isWideMode,
    getHeight,
    isMiniHeight,
    isTooMiniHeight,
    convertMessageCodeToMessage
}