import React from "react";
import { View, Text, TouchableOpacity, StyleSheet, Image, Linking, Platform, TextInput, Alert } from "react-native";
import config from './../config';
import AddressIcon from './../assets/icons/address.svg';
import RightChevronIcon from './../assets/icons/right-chevron.svg';
import I18n from './../utils/i18n';
import consumptionsService from './../services/consumptions';
import EyeVisibleIcon from './../assets/images/eye_visible.svg';
import EyeHiddenIcon from './../assets/images/eye_hidden.svg';

const default_images = {
    'ski_station': require('./../assets/images/ski_station_default.jpg'),
}

class ConsumptionRowHorizontal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            input_value: String(this.props?.current_quantity ? this.props.current_quantity : ''),
            online_hidden: this.props?.online_hidden
        }

    }

    async setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, resolve)
        });
    }

    componentDidUpdate(nextProps, nextState) {
        nextState = {
            ...nextState,
            ...nextProps
        }
    }

    openGps = (lat, lng, title = null) => {
        const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
        const latLng = `${lat},${lng}`;
        const label = title ? title : latLng;
        const url = Platform.select({
            ios: `${scheme}${label}@${latLng}`,
            android: `${scheme}${latLng}(${label})`,
            web: `https://www.google.com/maps/search/?api=1&query=${latLng}`
        });
        Linking.openURL(url);
    }
    
    render() {

        let defaultStyle = {backgroundColor: this.props?.background_color ? config.consumptionColors[this.props.background_color] : 'white', padding: 10, margin: 5, flex: 1, flexDirection: 'column', borderRadius: 7}
        if (this.props?.grouped) {
            defaultStyle = {...defaultStyle, margin: 0, marginLeft: 5, marginRight: 5, borderTopWidth: 1, borderTopColor: 'darkgray', borderRadius: 0}
        }
        if (this.props?.groupedFirst) {
            defaultStyle = {...defaultStyle, borderTopLeftRadius: 7, borderTopRightRadius: 7, borderTopWidth: 0}
        }
        if (this.props?.groupedLast) {
            defaultStyle = {...defaultStyle, borderBottomLeftRadius: 7, borderBottomRightRadius: 7, marginBottom: 5}
        }

        return (
            <TouchableOpacity
                style={defaultStyle}
                onPress={this.props?.onClick}
                onLongPress={async () => {

                    if (this.props.role != config.roles.CASHIER.key) {
                        return;
                    }

                    let update_res = consumptionsService.update_consumption_online_hidden(this.props._id, ((this.state.online_hidden) ? false : true));
                                
                    let updated_online_hidden = ((this.state.online_hidden) ? false : true);
                    this.setState({online_hidden: updated_online_hidden});
                    
                    let server_online_hidden = (await update_res).online_hidden;
                    if (server_online_hidden != updated_online_hidden) {
                        Alert.alert(
                            I18n.t('error'),
                            I18n.t('error_updating_online_hidden'),
                            [{ text: I18n.t('ok') }]
                        );

                        this.setState({online_hidden: server_online_hidden});
                    }
                }}
            >
                {(this.props?.image_link || this.props?.default_image) ? (
                    <View style={{width: '100%'}}>
                        <Image
                            style={{width: '100%', height: (this.props?.image_height ? this.props.image_height : 100), borderRadius: 7}}
                            source={ this.props?.image_link ? {uri: this.props.image_link} : default_images[this.props.default_image] }
                        />
                        {(this.props?.location?.coordinates?.[0] && this.props?.location?.coordinates?.[1]) ? (
                            <View style={{position: 'absolute', margin: 10}}>
                                <TouchableOpacity
                                    style={{width: 60, height: 40, borderRadius: 10, backgroundColor: config.primaryColor, justifyContent: 'center', alignItems: 'center'}}
                                    onPress={() => this.openGps(this.props?.location?.coordinates?.[0], this.props?.location?.coordinates?.[1], this.props.title)}
                                >
                                    {(Platform.OS === 'web') ? (
                                        <Image source={require('./../assets/icons/address.svg')} style={{width: 20, height: 20}} />
                                    ) : (
                                        <AddressIcon width={20} height={20} />
                                    )}
                                </TouchableOpacity>
                            </View>
                        ) : null}
                    </View>
                ) : null}
                <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>

                    {(this.props?.role == config.roles.CASHIER.key) ? (
                        (this.state.online_hidden) ? (
                            (Platform.OS === 'web') ?
                                <Image source={require('./../assets/images/eye_hidden.svg')} style={{width: 30, height: 30, marginRight: 5}} /> :
                                <EyeHiddenIcon width={30} height={30} style={{marginRight: 5}} />
                        ) : (
                            (Platform.OS === 'web') ?
                                <Image source={require('./../assets/images/eye_visible.svg')} style={{width: 30, height: 30, marginRight: 5}} /> :
                                <EyeVisibleIcon width={30} height={30} style={{marginRight: 5}} />
                        )
                    ) : null}

                    <View style={{flex: 1, flexDirection: 'column'}}>
                        {this.props?.title ? (
                            <Text style={{fontWeight: '700', fontSize: 17, color: 'black'}}>{this.props?.title}</Text>
                        ) : null}
                        {this.props?.secondary_title ? (
                            <Text style={{fontSize: 15, color: 'black', fontWeight: '600'}}>{this.props?.secondary_title}</Text>
                        ) : null}
                        {this.props?.description ? (
                            <Text style={{fontSize: 15, color: 'black', fontWeight: '600'}}>{this.props?.description}</Text>
                        ) : null}
                    </View>


                    {(this.props?.type === config.consumptionInputType.INPUT.key) ? (
                        <>
                            <TextInput
                                style={styles.input}
                                placeholder={'0.00'}
                                placeholderTextColor={'gray'}
                                value={this.state.input_value}
                                onChangeText={async (text) => {
                                    try {
                                        text = text.replace(',', '.');
                                        text = text.replace('-', '');
                                        await this.setStateAsync({input_value: String(text)});
                                        
                                        let number = parseFloat(this.state.input_value);
                                        if (isNaN(number)) {
                                            number = 0;
                                        }

                                        let round = this.props?.round ? this.props.round : 0.01;
                                        number = Math.round(number / round) * round;
                                        
                                        number = number.toFixed(2);
                                        this.props?.changeCallback?.(parseFloat(number));
                                    } catch (e) {
                                        await this.setState({input_value: ''});
                                        this.props?.changeCallback?.(0);
                                    }
                                }}
                                onBlur={async () => {
                                    try {
                                        let number = parseFloat(this.state.input_value);
                                        if (isNaN(number)) {
                                            number = 0;
                                        }

                                        let round = this.props?.round ? this.props.round : 0.01;
                                        number = Math.round(number / round) * round;

                                        number = number.toFixed(2);
                                        await this.setStateAsync({input_value: number});
                                        this.props?.changeCallback?.(parseFloat(number));
                                    } catch (e) {
                                        await this.setStateAsync({input_value: ''});
                                        this.props?.changeCallback?.(0);
                                    }
                                }}
                                keyboardType={'numeric'}
                            />

                        </>
                    ) : (
                        (this.props?.current_quantity > 0) ? (
                            <>
                                <View style={styles.buttonGroup}>
                                    <View>
                                        <TouchableOpacity
                                            style={styles.touchableButton}
                                            onPress={this.props?.removeCallback}
                                        >
                                            <Text style={styles.buttonText}>-</Text>
                                        </TouchableOpacity>
                                    </View>
                                    <Text style={styles.count}>
                                        {this.props?.current_quantity}
                                    </Text>
                                    <View>
                                        <TouchableOpacity
                                            style={styles.touchableButton}
                                            onPress={this.props?.addCallback}
                                        >
                                            <Text style={styles.buttonText}>+</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            </>
                        ) : (
                            <>
                                <View style={styles.buttonGroup}>
                                    <TouchableOpacity
                                        style={styles.wideTouchableButton}
                                        onPress={this.props?.addCallback}
                                    >
                                        <Text style={styles.wideButtonText}>+ {I18n.t('add')}</Text>
                                    </TouchableOpacity>
                                </View>
                            </>
                        )
                    )}

                </View>

            </TouchableOpacity>
        );
    }
}

const styles = StyleSheet.create({
    navigation_button_text: {
        color: 'gray'
    },
    buttonGroup: {
        // height: 50,
        padding: 5,
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        // backgroundColor: config.primaryColor,
        borderColor: 'rgba(255, 255, 255, 1)',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 10,
        borderWidth: 2,
        maxWidth: 200,
    },
    wideTouchableButton: {
        // backgroundColor: 'rgba(8, 96, 126, 0.15)',
        backgroundColor: config.primaryColor,
        borderColor: 'rgba(255, 255, 255, 0.8)',
        // margin: 10,
        borderRadius: 10,
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
        // width: 80,
        paddingLeft: 20,
        paddingRight: 20,
        minHeight: 40
        // borderWidth: 3,
    },
    touchableButton: {
        // backgroundColor: 'rgba(8, 96, 126, 0.15)',
        backgroundColor: config.primaryColor,
        borderColor: 'rgba(255, 255, 255, 0.8)',
        // margin: 10,
        borderRadius: 10,
        flexDirection: "row",
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        minHeight: 40
        // borderWidth: 3,
    },
    wideButtonText: {
        fontSize: 20,
        // color: 'rgba(8, 96, 126, 1)',
        color: 'white'
    },
    buttonText: {
        fontSize: 29,
        // color: 'rgba(8, 96, 126, 1)',
        color: 'white'
    },
    count:{
        fontSize: 22,
        paddingLeft: 5,
        paddingRight: 5,
        // width: 40,
        textAlign: 'center',
        color: 'black'
    },
    input: {
        borderColor: config.primaryColor,
        borderRadius: 5,
        height: 50,
        width: 100,
        backgroundColor: 'white',
        borderWidth: 2,
        color: 'black',
        textAlign: 'center',
    },
});

export default ConsumptionRowHorizontal;