import React from "react";
import { Alert, ScrollView, Text, TouchableOpacity, View } from "react-native";
import statisticsService from "../services/statistics";
import i18n from "../utils/i18n";
import { formatPriceWithCurrencyCode } from "../utils/common";
import config from "../config";
import moment from "moment";

class DailyReportPrintPreview extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			data: {}
		};
	}

	async componentDidMount() {
		let data = await statisticsService.getPrintPreviewDailyStatistics();
		this.setState({ data });
	}

	async printDailyReport() {
		try {

			// print report
			let printRes = await statisticsService.printDailyStatistics();
			if (printRes.error) {

				Alert.alert(
					i18n.t('error'),
					i18n.t(printRes.error_code)
				);
				return
			}

			Alert.alert(
				i18n.t('print_successful')
			);
			this.props.navigation.goBack();

		} catch (error) {
			console.log("Error generating report", error.message);
			Alert.alert(
				i18n.t('error'),
				i18n.t('unknown_error')
			);
		}
	}

	render() {

		let date_and_user_open = [
			{ left: i18n.t('date_open') + ':', right: (this.state?.data?.dateOpen ? moment(this.state?.data?.dateOpen).format(i18n.t('OPTIONS.timestampFormatFull')) : null ) || '' },
			{ left: i18n.t('user_open') + ':', right: this.state?.data?.userOpen || '' }
		]

		let date_and_user_close = [
			{ left: i18n.t('date_close') + ':', right: (this.state?.data?.dateClose ? moment(this.state?.data?.dateClose).format(i18n.t('OPTIONS.timestampFormatFull')) : null) || '' },
			{ left: i18n.t('user_close') + ':', right: this.state?.data?.userClose || '' }
		]

		let turnover_and_tickets = [
			{ left: i18n.t('gross_turnover_with_vat') + ':', right: formatPriceWithCurrencyCode((this.state?.data?.totalRevenue || '0'), null, true) },
			{ left: i18n.t('discounts_including_vat') + ':', right: formatPriceWithCurrencyCode((this.state?.data?.discountsIncludingVat || '0'), null, true) },
			{ left: i18n.t('net_turnover_with_vat') + ':', right: formatPriceWithCurrencyCode((this.state?.data?.totalRevenue || '0'), null, true) },
			{ left: i18n.t('total_vat') + ':', right: formatPriceWithCurrencyCode((this.state?.data?.totalVat || '0'), null, true) },
			{ left: i18n.t('total_without_vat') + ':', right: formatPriceWithCurrencyCode((this.state?.data?.totalRevenue || '0'), null, true) },
			{ left: '', right: '' },
			{ left: i18n.t('total_number_of_tickets') + ':', right: this.state?.data?.numberOfTickets || '0' },
			{ left: i18n.t('average_price_of_ticket') + ':', right: formatPriceWithCurrencyCode((this.state?.data?.averagePriceOfTicket || '0'), null, true) },
		]

		let cash_control = [
			{ left: i18n.t('cash_control'), right: '' },
			{ left: i18n.t('expected') + ':', right: formatPriceWithCurrencyCode((this.state?.data?.cashRevenue || '0'), null, true) },
			{ left: i18n.t('actual') + ':', right: formatPriceWithCurrencyCode((this.state?.data?.actualCashRevenue || '0'), null, true) },
			{ left: i18n.t('difference') + ':', right: formatPriceWithCurrencyCode((this.state?.data?.cashDifference || '0'), null, true) },
		]

		let payment_methods = [
			{ left: i18n.t('payment_method_including_tax'), right: '' },
			{ left: i18n.t('cash') + ':', right: formatPriceWithCurrencyCode((this.state?.data?.cashRevenue || '0'), null, true) },
			{ left: i18n.t('card') + ':', right: formatPriceWithCurrencyCode((this.state?.data?.cardRevenue || '0'), null, true) },
			{ left: i18n.t('payrexx') + ':', right: formatPriceWithCurrencyCode((this.state?.data?.payrexxRevenue || '0'), null, true) },
			{ left: i18n.t('twint') + ':', right: formatPriceWithCurrencyCode((this.state?.data?.twintRevenue || '0'), null, true) },
		]

		let revenue_by_group = this.state?.data?.revenueByGroup || [];

		return (
			<ScrollView>

				{/* barName */}
				<Text
					style={{
						fontSize: 20,
						fontWeight: 'bold',
						textAlign: 'center',
						marginTop: 20,
						color: 'black'
					}}
				>{this.state?.data?.barName || ''}</Text>

				{/* hr */}
				<View
					style={{
						borderBottomColor: 'black',
						borderBottomWidth: 1,
						marginTop: 20,
						marginBottom: 20,
						// dotted line
						borderStyle: 'dashed',
					}}
				/>

				{/* date open & user open */}
				{(date_and_user_open || []).map((item, index) => {
					return (
						<View
							key={index}
							style={{
								flexDirection: 'row',
								justifyContent: 'space-between',
								paddingHorizontal: 10
							}}
						>
							<Text style={{ fontSize: 15, color: 'black' }}>{item.left}</Text>
							<Text style={{ fontSize: 15, color: 'black' }}>{item.right}</Text>
						</View>
					)
				})}

				{/* hr */}
				<View
					style={{
						borderBottomColor: 'black',
						borderBottomWidth: 1,
						marginTop: 20,
						marginBottom: 20,
						// dotted line
						borderStyle: 'dashed',
					}}
				/>

				{/* date close & user close */}
				{/* date open & user open */}
				{(date_and_user_close || []).map((item, index) => {
					return (
						<View
							key={index}
							style={{
								flexDirection: 'row',
								justifyContent: 'space-between',
								paddingHorizontal: 10
							}}
						>
							<Text style={{ fontSize: 15, color: 'black' }}>{item.left}</Text>
							<Text style={{ fontSize: 15, color: 'black' }}>{item.right}</Text>
						</View>
					)
				})}

				{/* hr */}
				<View
					style={{
						borderBottomColor: 'black',
						borderBottomWidth: 1,
						marginTop: 20,
						marginBottom: 20,
						// dotted line
						borderStyle: 'dashed',
					}}
				/>

				{/* turnover_and_tickets */}
				{(turnover_and_tickets || []).map((item, index) => {
					return (
						<View
							key={index}
							style={{
								flexDirection: 'row',
								justifyContent: 'space-between',
								paddingHorizontal: 10
							}}
						>
							<Text style={{ fontSize: 15, color: 'black' }}>{item.left}</Text>
							<Text style={{ fontSize: 15, color: 'black' }}>{item.right}</Text>
						</View>
					)
				})}

				{/* hr */}
				<View
					style={{
						borderBottomColor: 'black',
						borderBottomWidth: 1,
						marginTop: 20,
						marginBottom: 20,
						// dotted line
						borderStyle: 'dashed',
					}}
				/>

				{/* cash_control */}
				{(cash_control || []).map((item, index) => {
					return (
						<View
							key={index}
							style={{
								flexDirection: 'row',
								justifyContent: 'space-between',
								paddingHorizontal: 10
							}}
						>
							<Text style={{ fontSize: 15, color: 'black' }}>{item.left}</Text>
							<Text style={{ fontSize: 15, color: 'black' }}>{item.right}</Text>
						</View>
					)
				})}

				{/* hr */}
				<View
					style={{
						borderBottomColor: 'black',
						borderBottomWidth: 1,
						marginTop: 20,
						marginBottom: 20,
						// dotted line
						borderStyle: 'dashed',
					}}
				/>

				{/* payment_methods */}
				{(payment_methods || []).map((item, index) => {
					return (
						<View
							key={index}
							style={{
								flexDirection: 'row',
								justifyContent: 'space-between',
								paddingHorizontal: 10
							}}
						>
							<Text style={{ fontSize: 15, color: 'black' }}>{item.left}</Text>
							<Text style={{ fontSize: 15, color: 'black' }}>{item.right}</Text>
						</View>
					)
				})}

				{/* hr */}
				<View
					style={{
						borderBottomColor: 'black',
						borderBottomWidth: 1,
						marginTop: 20,
						marginBottom: 20,
						// dotted line
						borderStyle: 'dashed',
					}}
				/>

				{/* revenue_by_group */}
				{(revenue_by_group || []).map((item, index) => {
					return (
						<View key={'revenue_by_group_' + index}>
							<View
								style={{
									flexDirection: 'row',
									justifyContent: 'space-between',
									paddingHorizontal: 10
								}}
							>
								<Text style={{ fontSize: 15, color: 'black' }}>{i18n.t('group') + ':'}</Text>
								<Text style={{ fontSize: 15, color: 'black' }}>{item.groupName || ''}</Text>
							</View>
							<View
								style={{
									flexDirection: 'row',
									justifyContent: 'space-between',
									paddingHorizontal: 10
								}}
							>
								<Text style={{ fontSize: 15, color: 'black' }}>{i18n.t('total_revenue') + ':'}</Text>
								<Text style={{ fontSize: 15, color: 'black' }}>{formatPriceWithCurrencyCode((item.total || '0'), null, true)}</Text>
							</View>
							{/* hr */}
							<View
								style={{
									borderBottomColor: 'black',
									borderBottomWidth: 1,
									marginTop: 20,
									marginBottom: 20,
									// dotted line
									borderStyle: 'dashed',
								}}
							/>
						</View>
					)
				})}

				{/* total */}
				<Text
					style={{
						fontSize: 25,
						fontWeight: 'bold',
						textAlign: 'right',
						marginBottom: 20,
						color: 'black',
						paddingHorizontal: 10
					}}
				>{formatPriceWithCurrencyCode((this.state?.data?.totalRevenue || '0'), null, true)}</Text>


				<TouchableOpacity
					style={{ backgroundColor: config.primaryColor, padding: 10, borderRadius: 5, margin: 10 }}
					onPress={async () => {
						await this.printDailyReport();
					}}
				>
					<Text style={{ color: 'white', textAlign: 'center', fontSize: 17, fontWeight: '700' }}>{i18n.t('print')}</Text>
				</TouchableOpacity>



			</ScrollView>
		);
	}
}

export default DailyReportPrintPreview;